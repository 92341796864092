<template>
  <div>
    <svg :height="`${height}px` || '14px'" :width="`${width}px` || '12px'" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 2.19992C6.04947 2.19992 6.09737 2.19318 6.14282 2.18058C6.36801 2.11813 6.5333 1.91166 6.5333 1.66659H6.53333V1.66659C6.53333 1.37203 6.29455 1.13325 6 1.13325C5.70545 1.13325 5.46667 1.37203 5.46667 1.66659C5.46667 1.96114 5.70545 2.19992 6 2.19992ZM6 0.333252C5.26362 0.333252 4.66667 0.930206 4.66667 1.66659L1.33333 1.66659C0.596954 1.66659 0 2.26354 0 2.99992V12.3333C0 13.0696 0.596954 13.6666 1.33333 13.6666H10.6667C11.403 13.6666 12 13.0696 12 12.3333V2.99992C12 2.26354 11.403 1.66659 10.6667 1.66659L7.33333 1.66659V1.66659C7.33333 0.930206 6.73638 0.333252 6 0.333252ZM9.70498 5.33439C9.88971 5.12914 9.87307 4.81299 9.66782 4.62826C9.46256 4.44353 9.14642 4.46017 8.96169 4.66543L5.1093 8.94586L3.68187 7.55808C3.48388 7.36558 3.16733 7.37004 2.97484 7.56804C2.78234 7.76603 2.7868 8.08258 2.98479 8.27507L4.78479 10.0251C4.88227 10.1198 5.01413 10.1708 5.15 10.1663C5.28587 10.1618 5.41404 10.1021 5.50498 10.0011L9.70498 5.33439Z"
        :fill="`${color}`"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#00B3BE'
    },
    width: {
      type: String,
      default: '12'
    },
    height: {
      type: String,
      default: '14'
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
